import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import cx from "classnames";
import { SVG } from "../../components/svg";
import CrossButton from "../../components/CrossButton";
import ProgressiveImage from "react-progressive-image";

import config from "../../config.json";

import "./style.scss";

interface Face {
  x: number;
  y: number;
  width: number;
}

interface Props extends RouteComponentProps {
  photosIds: string[];
  photosMap: {
    [key: string]: {
      face: Face | undefined;
      height: number;
      width: number;
      year: number;
    };
  };
}

interface State {
  currentPhoto: number;
  timeBetweenPhotos: number;
  paused: boolean;
  direction: "forward" | "backward";
}

const FACE_WIDTH = 70;
const FACE_HEIGHT = FACE_WIDTH / 0.7252196168;

class Time extends React.Component<Props, State> {
  state = {
    currentPhoto: 0,
    timeBetweenPhotos: 200,
    paused: false,
    direction: "forward" as State["direction"],
  };

  playbackTimeout: number | undefined;

  componentDidMount() {
    this.playbackTimeout = setTimeout(this.proceedPlayback, this.state.timeBetweenPhotos);
  }

  renderPage(children: React.ReactNode) {
    const {
      history: { goBack },
    } = this.props;

    return (
      <div className="page-time">
        {children}
        <div className="link-button-wrapper link-button-wrapper__start">
          <Link to="/" className="link-button">
            start
          </Link>
        </div>
        <div className="cross-button-wrapper">{<CrossButton onClick={goBack} />}</div>
      </div>
    );
  }

  showNext = () => {
    this.setState(({ currentPhoto }) => {
      const photosLength = this.props.photosIds.length;
      let newIndex = 0;

      if (currentPhoto !== photosLength - 1) {
        newIndex = currentPhoto + 1;
      }

      return {
        currentPhoto: newIndex,
      };
    });
  };

  showPrev = () => {
    this.setState(({ currentPhoto }) => {
      const photosLength = this.props.photosIds.length;
      let newIndex = photosLength - 1;

      if (currentPhoto !== 0) {
        newIndex = currentPhoto - 1;
      }

      return {
        currentPhoto: newIndex,
      };
    });
  };

  onChangeTime = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = Number(e.currentTarget.value);

    this.setState({
      timeBetweenPhotos: value,
    });
  };

  proceedPlayback = () => {
    const { direction } = this.state;

    clearTimeout(this.playbackTimeout);

    if (direction === "forward") {
      this.showNext(true);
    } else {
      this.showPrev(true);
    }

    this.playbackTimeout = setTimeout(this.proceedPlayback, this.state.timeBetweenPhotos);
  };

  onPlay = () => {
    this.setState(
      {
        paused: false,
        direction: "forward",
      },
      () => {
        this.proceedPlayback();
      }
    );
  };

  onPlayReverse = () => {
    this.setState(
      {
        paused: false,
        direction: "backward",
      },
      () => {
        this.proceedPlayback();
      }
    );
  };

  componentWillUnmount() {
    clearTimeout(this.playbackTimeout);
  }

  onPause = () => {
    clearTimeout(this.playbackTimeout);

    this.setState({
      paused: true,
    });
  };

  toggleDirection = () => {
    this.setState(({ direction }) => {
      return {
        direction: direction === "backward" ? "forward" : "backward",
      };
    });
  };

  render() {
    const { photosIds, photosMap } = this.props;
    const { currentPhoto: currentPhotoIndex, paused, direction } = this.state;

    const currentPhoto = photosIds[currentPhotoIndex];
    const imageMetadata = photosMap[currentPhoto!]!;

    const scale = imageMetadata.face.width / FACE_WIDTH;
    const width = imageMetadata.width / scale;
    const height = imageMetadata.height / scale;
    const top = -imageMetadata.face.y / scale;
    const left = -imageMetadata.face.x / scale;

    const firstYear = photosMap[photosIds[0]].year;
    const currentYear = imageMetadata.year;
    const lastYear = photosMap[photosIds[photosIds.length - 1]].year;

    const progress = ((currentYear - firstYear) / (lastYear - firstYear)) * 100;

    return this.renderPage(
      <>
        <div className="canvas" onClick={this.onPause}>
          <div className="face" style={{ width: FACE_WIDTH, height: FACE_HEIGHT }}>
            {paused ? (
              <ProgressiveImage
                src={`${process.env.REACT_APP_PATH + config.photosUrl}${currentPhoto}`}
                placeholder={`${process.env.REACT_APP_PATH + config.photosMinUrl}${currentPhoto}`}
              >
                {(src: string) => (
                  <Link to={`/photos/${currentPhoto}`}>
                    <img
                      src={src}
                      width={width}
                      key={currentPhoto}
                      height={height}
                      alt={imageMetadata.year.toString()}
                      style={{ top: `${top}px`, left: `${left}px` }}
                    />
                  </Link>
                )}
              </ProgressiveImage>
            ) : (
              <img
                src={`${process.env.REACT_APP_PATH + config.photosMinUrl}${currentPhoto}`}
                width={width}
                key={currentPhoto}
                height={height}
                alt={imageMetadata.year.toString()}
                style={{ top: `${top}px`, left: `${left}px` }}
              />
            )}
          </div>
        </div>
        <div className="timeline-section">
          <div className="timeline__column-before">
            <div className="first-year">1931</div>
            <button
              className={cx("button", "play-reverse-button", {
                "button--active": paused === false && direction === "backward",
              })}
              type="button"
              onClick={this.onPlayReverse}
            >
              <span className="state-hover">
                <SVG id="arrow-head-bold" />
              </span>
              <span className="state-regular">
                <SVG id="arrow-head" />
              </span>
            </button>
          </div>
          <div className="timeline__column-after">
            <button
              className={cx("button", "play-button", { "button--active": paused === false && direction === "forward" })}
              type="button"
              onClick={this.onPlay}
            >
              <span className="state-hover">
                <SVG id="arrow-head-bold" />
              </span>
              <span className="state-regular">
                <SVG id="arrow-head" />
              </span>
            </button>
            <div className="last-year">2019</div>
          </div>
          <div className="timeline">
            <div className="timeline-progress" style={{ width: `${progress}%` }} />
          </div>
          <div className="current-year__wrapper">
            <div className="current-year" style={{ left: `${progress}%` }}>
              <div className="current-year__background">
                <SVG id="ellipse" />
              </div>
              {currentYear}
            </div>
          </div>
        </div>
        <div className="controls">
          <button className={cx("button", "prev-button", { hidden: !paused })} onClick={() => this.showPrev()}>
            <span className="state-hover">
              <SVG id="next-bold" />
            </span>
            <span className="state-regular">
              <SVG id="next" />
            </span>
          </button>
          <button className={cx("button", "pause-button", { "button--active": paused })} onClick={this.onPause}>
            <span className="state-hover">
              <SVG id="pause-bold" />
            </span>
            <span className="state-regular">
              <SVG id="pause" />
            </span>
          </button>
          <button
            className={cx("button", "next-button", { hidden: !paused })}
            type="button"
            onClick={() => this.showNext()}
          >
            <span className="state-hover">
              <SVG id="next-bold" />
            </span>
            <span className="state-regular">
              <SVG id="next" />
            </span>
          </button>
        </div>
        <div className="tag-name">#time</div>
      </>
    );
  }
}

export default Time;
