import * as React from "react";
import cx from "classnames";
import Index from "../Index";

import "./style.scss";

type Props = {
  tags: string[];
  tagsMainPhotoMap: any;
  onShow?: () => void;
  onHide?: () => void;
  visible: boolean;
};

class IndexController extends React.Component<Props> {
  hideIndex = () => {
    this.props.onHide && this.props.onHide();
  };

  render() {
    const { tags, visible } = this.props;

    return (
      <div
        className={cx("index-controller", "index-wrapper", {
          "index-wrapper--visible": visible,
        })}
      >
        <Index
          onCloseClick={this.hideIndex}
          visible={visible}
          tagsMainPhotoMap={this.props.tagsMainPhotoMap}
          tags={tags}
        />
      </div>
    );
  }
}

export default IndexController;
