import * as React from "react";

import config from "../config.json";

import "./style.scss";

type Props = {
  photo: string;
};

class PhotoBackground extends React.Component<Props> {
  render() {
    const { photo } = this.props;

    return (
      <div className="photo-background">
        <img src={`${process.env.REACT_APP_PATH + config.photosMinUrl}${photo}`} alt="background" />
      </div>
    );
  }
}

export default PhotoBackground;
