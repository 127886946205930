import * as React from "react";
import { SVG } from "../svg";

import "./style.scss";

type Props = {
  onClick: () => void;
};

class CrossButton extends React.Component<Props> {
  render() {
    return (
      <button className="cross-button" onClick={this.props.onClick}>
        <span className="state-hover">
          <SVG id="x-bold" />
        </span>
        <span className="state-regular">
          <SVG id="x" />
        </span>
      </button>
    );
  }
}

export default CrossButton;
