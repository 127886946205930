import * as React from "react";
import Index from "../../components/Index";

import config from "../../config.json";

import "./style.scss";

type Props = {
  tags: string[];
  tagsMainPhotoMap: any;
  onShowIndex: () => void;
  onShowAbout: () => void;
  onShowIntro: () => void;
};

class Home extends React.Component<Props> {
  render() {
    return (
      <div className="page-home">
        <div className="main-photo">
          <img src={`${process.env.REACT_APP_PATH + config.photosUrl}ZK_A_0011.webp`} alt="Welcome" />
        </div>
        <div className="index-wrapper">
          <Index visible={true} tags={this.props.tags} tagsMainPhotoMap={this.props.tagsMainPhotoMap} />
        </div>
        <div className="link-button-wrapper link-button-wrapper__about">
          <button className="link-button" onClick={this.props.onShowAbout}>
            about
          </button>
        </div>
        <div className="link-button-wrapper link-button-wrapper__intro">
          <button className="link-button" onClick={this.props.onShowIntro}>
            intro
          </button>
        </div>
      </div>
    );
  }
}

export default Home;
