import * as React from "react";
import cx from "classnames";
import config from "../../config.json";

import "./style.scss";

class Intro extends React.Component<any> {
  state = {
    introVisible: true,
    backgroundVisible: false,
    videoVisible: false,
  };
  videoElementRef = React.createRef<HTMLVideoElement>();

  componentDidMount() {
    document.body.getBoundingClientRect();

    this.setState({
      backgroundVisible: true,
      videoVisible: true,
    });

    this.videoElementRef.current!.play();
  }

  onVideoEnded = () => {
    this.setState({
      introVisible: false,
    });
  };

  onIntroTransitionEnd = (e) => {
    if (e.target.classList.contains("intro")) {
      this.props.onFinished();
    }
  };

  render() {
    return (
      <div
        className={cx("intro", {
          "intro--visible": this.state.introVisible,
        })}
        onTransitionEnd={this.onIntroTransitionEnd}
      >
        <div
          className={cx("intro__background", {
            "intro__background--visible": this.state.backgroundVisible,
          })}
        />
        <div className={cx("video-wrapper", { "video-wrapper--visible": this.state.videoVisible })}>
          <video ref={this.videoElementRef} onEnded={this.onVideoEnded}>
            <source
              src={`${process.env.REACT_APP_PATH + config.mediaUrl}intro.webm`}
              type={`video/webm;codecs="vp9"`}
            />
            <source src={`${process.env.REACT_APP_PATH + config.mediaUrl}intro.mov`} type="video/quicktime" />
          </video>
        </div>
      </div>
    );
  }
}

export default Intro;
