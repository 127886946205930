import * as React from "react";
import config from "../../config.json";

import "./style.scss";

class SmallScreenBlocker extends React.Component {
  render() {
    return (
      <div className="small-screen-blocker">
        <div className="image">
          <img src={`${process.env.REACT_APP_PATH + config.mediaUrl}main_page_small_screen.jpg`} alt="" />
        </div>
        <div className="message">EXPLORE THIS PROJECT ON A BIGGER SCREEN</div>
        <div className="ellipsis-wrapper">
          {/* eslint-disable-next-line */}
          <img
            src={`${process.env.REACT_APP_PATH + config.mediaUrl}elipsa.svg`}
            className="ellipsis"
            alt="Take a picture of me without askin why"
          />
        </div>
      </div>
    );
  }
}

export default SmallScreenBlocker;
