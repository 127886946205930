import * as React from "react";

import CrossButton from "../CrossButton";

import "./style.scss";

type Props = {
  onCloseClick: () => void;
};

class About extends React.Component<Props> {
  onCrossButtonClick = () => {
    this.props.onCloseClick();
  };

  render() {
    return (
      <div className="about-project">
        <div className="main-text">
          <h1>
            take a picture of me
            <br /> without asking why
          </h1>
          <p>
            Zygmunt Kunert felt the need to be photographed — somewhere, anywhere; whatever his clothes; regardless of
            the time of the day; while making, holding, or simply being.
          </p>
          <p>
            He would continue to expand his cycle of photographs over the span of nearly 70 years. At first, it appears
            to be quite an ordinary collection of pictures — however, the palpable consequence and repetitiveness of
            their characteristics tell a different story. Aware or not, throughout his life, he created an
            auto-photo-biography project, utilising the randomness of spatio-temporal circumstances. It was, as it were,
            an unceasing process of self-preservation — a persistent representation of the self in a strictly defined
            manner.
          </p>
          <p>
            I made an attempt to catalogue these self-portraits, but also to interpret, or perhaps even overinterpret
            this entire series of photos, taken by such an unprofessional photographer.
          </p>
          <p>
            I sought to answer the question: what was the reason behind the ceaseless process of taking his own pictures
            — a process that so deeply reflected the consequent need to exist in space and time?
          </p>
          <p>
            I tried to give this series of my grandfather’s self-portraits a new name, because, paradoxically, it has
            never been named or presented by the author. I perceive his work as an opus of a postmodern artist — who had
            never held an exhibition.
          </p>
        </div>
        <div className="sub-text">
          <p>
            All rights reserved © photography by Zygmunt Kunert • designed by{" "}
            <a href="https://www.behance.net/kunert">Joanna Kunert</a> • web developer{" "}
            <a href="https://www.linkedin.com/in/mariuszpilarczyk">Mariusz Pilarczyk</a>
          </p>
        </div>
        <div className="cross-button-wrapper">
          <CrossButton onClick={this.onCrossButtonClick} />
        </div>
      </div>
    );
  }
}

export default About;
