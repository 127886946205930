import * as React from "react";
import cx from "classnames";
import { SVG } from "../../components/svg";
import config from "../../config.json";
import imagePrefetcher from "../../modules/ImagePrefetcher";

import "./style.scss";

type State = {
  backgroundLoaded: boolean;
  imagesPrefetched: boolean;
  totalImagesCount: number | undefined;
  imagesCount: number;
  videoShown: boolean;
  videoPlayed: boolean;
  visible: boolean;
};

type Props = {
  imagesLoaded: (photos: any) => void;
};

class Loader extends React.PureComponent<Props, State> {
  timeout?: number;
  photos?: any;
  mainPhotos?: any;
  played: boolean = false;
  videoTransitionEnded: boolean = false;
  videoElementRef = React.createRef<HTMLVideoElement>();

  state = {
    showMessage: false,
    backgroundLoaded: false,
    imagesPrefetched: false,
    totalImagesCount: undefined,
    imagesCount: undefined,
    videoShown: false,
    videoPlayed: false,
    visible: false,
  };

  componentDidMount() {
    const { db: data } = this.props;

    this.photos = data.photos.map((photo) => {
      const [name, extension] = photo.id.split(".");

      if (extension.toLowerCase() === "jpg") {
        return {
          ...photo,
          id: name + ".webp",
        };
      } else {
        return {
          ...photo,
          id: name + "." + extension,
        };
      }
    });

    imagePrefetcher.prefetchInitialImages(
      this.photos.map((photo) => photo.id),
      () => {
        this.setState(({ imagesCount }) => {
          return {
            imagesCount: imagesCount - 1,
          };
        });
      }
    );

    this.mainPhotos = data.mainPhotos.map((photo) => {
      const photoUrl = photo.photo;

      const [name, extension] = photoUrl.split(".");

      if (extension.toLowerCase() === "jpg") {
        return { tag: photo.tag, photo: name + ".webp" };
      } else {
        return { tag: photo.tag, photo: name + "." + extension };
      }
    });

    this.setState({
      imagesCount: this.photos.length,
      totalImagesCount: this.photos.length,
    });

    setTimeout(() => {
      // window.innerHeight; // eslint-disable-line
      this.setState({
        visible: true,
      });
    }, 0);
  }

  componentDidUpdate() {
    if (this.state.imagesCount === 0 && this.state.imagesPrefetched === false) {
      this.setState({
        imagesPrefetched: true,
      });
    }
  }

  onStartClicked = () => {
    this.setState({
      videoShown: true,
    });

    this.videoElementRef.current!.play();
  };

  onVideoEnded = () => {
    if (!this.skipped) {
      this.props.imagesLoaded(this.photos, this.mainPhotos);
    }
  };

  onSkip = () => {
    this.skipped = true;
    this.props.imagesLoaded(this.photos, this.mainPhotos);
  };

  render() {
    const { imagesCount, imagesPrefetched, videoShown, videoPlayed, visible } = this.state;

    return (
      <div className={cx("page-loader", { "page-loader--visible": visible })}>
        <div className={cx("background", { "background--hidden": videoShown })} style={{ backgroundColor: "#3b34fe" }}>
          <img src={`${process.env.REACT_APP_PATH + config.photosUrl}${config.mainImage}`} alt="" />
        </div>
        <div className={cx("counter", { "counter--hidden": videoShown || videoPlayed })}>
          <SVG id="ellipse" />
          <div className="counter__value">
            {imagesPrefetched ? <span onClick={this.onStartClicked}>PLAY</span> : imagesCount}
          </div>
        </div>
        <div
          className={cx("video-wrapper", { "video-wrapper--visible": videoShown })}
          onTransitionEnd={this.onVideoTransitionEnd}
        >
          <video ref={this.videoElementRef} onEnded={this.onVideoEnded}>
            <source
              src={`${process.env.REACT_APP_PATH + config.mediaUrl}intro.webm`}
              type={`video/webm;codecs="vp9"`}
            />
            <source src={`${process.env.REACT_APP_PATH + config.mediaUrl}intro.mov`} type="video/quicktime" />
          </video>
        </div>
        {videoShown && (
          <div className="link-button-wrapper link-button-wrapper__skip">
            <button className="link-button" onClick={this.onSkip}>
              skip
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default Loader;
