import * as React from "react";
import { withRouter } from "react-router-dom";
import * as d3 from "d3";
import * as d3Cloud from "d3-cloud";

import config from "../../config.json";
import CrossButton from "../CrossButton";

import "./style.scss";

type Props = {
  tags: string[];
  tagsMainPhotoMap: any;
  onCloseClick: () => void;
  visible: boolean;
};

const calculateDistance = (tagA, tagB) => {
  return Math.sqrt(Math.pow(tagA.left - tagB.left, 2) + Math.pow(tagA.top - tagB.top, 2));
};

class Index extends React.PureComponent<Props> {
  tagsContainerRef = React.createRef<HTMLDivElement>();
  canvasWidth: number = 0;
  canvasHeight: number = 0;

  state = {
    words: [],
  };

  onCrossButtonClick = () => {
    this.props.onCloseClick();
  };

  componentDidMount() {
    if (this.props.visible) {
      this.detectCanvasDimension();
      this.renderTags();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.visible && prevProps.visible === false) {
      this.detectCanvasDimension();
      d3.select("#tags-canvas").selectAll("*").remove();
      this.renderTags();
    }
  }

  detectCanvasDimension() {
    if (this.tagsContainerRef.current) {
      const { width, height } = this.tagsContainerRef.current.getBoundingClientRect();

      this.canvasHeight = height;
      this.canvasWidth = width;
    }
  }

  renderTags() {
    const layout = d3Cloud()
      .size([this.canvasWidth, this.canvasHeight])
      .words(
        this.props.tags.map(function (d) {
          return { text: d, size: 24 };
        })
      )
      .spiral("rectangular")
      .rotate(0)
      .padding(20)
      .font("IBM Plex Mono")
      .fontSize(function (d) {
        return d.size;
      })
      .on("end", this.onLayoutReady);
    layout.start();
  }

  onLayoutReady = (inputWords: any) => {
    const words: any = [];
    const wordsMap: any = {};

    inputWords.forEach((word: any) => {
      words.push({
        word: word.text,
        top: word.y,
        left: word.x - word.width / 2,
        offsetY: 0,
        offsetX: 0,
        width: word.width,
        height: word.height,
      });

      wordsMap[word.text] = {
        top: word.y,
        height: word.height,
        width: word.width,
        left: word.x - word.width / 2,
      };
    });

    this.setState({
      words,
      wordsMap,
    });
  };

  onMouseEnter = (e) => {
    const tag = e.target.textContent;

    const { words, wordsMap } = this.state;
    const hoveredWord = wordsMap[tag];
    const newWords = [];

    for (let word of words) {
      if (word.word !== hoveredWord.word) {
        const distance = calculateDistance(word, hoveredWord);

        const scale = 1 + Math.pow(30 / distance, 2);

        const newOffsetX = word.left * scale + hoveredWord.left * (1 - scale);
        const newOffsetY = word.top * scale + hoveredWord.top * (1 - scale);

        newWords.push({
          ...word,
          offsetX: newOffsetX - word.left,
          offsetY: newOffsetY - word.top,
        });
      } else {
        newWords.push(word);
      }
    }

    this.setState({
      words: newWords,
    });
  };

  onMouseLeave = () => {
    const newWords = [];

    for (let word of this.state.words) {
      newWords.push({ ...word, offsetX: 0, offsetY: 0 });
    }

    this.setState({
      words: newWords,
    });
  };

  onClick = (e: React.MouseEvent) => {
    this.props.history.push("/" + e.target.textContent);

    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  };

  render() {
    const { words } = this.state;

    return (
      <div className="index">
        <div className="tags" ref={this.tagsContainerRef}>
          <div className="canvas">
            {words.map(({ word, top, left, offsetX, width, height, offsetY }) => {
              const data = this.props.tagsMainPhotoMap[word];

              const currentWidth = data.width;
              const currentHeight = data.height;

              const newHeight = 112;
              const newWidth = (newHeight * currentWidth) / currentHeight;

              return (
                <div
                  key={word}
                  onMouseEnter={this.onMouseEnter}
                  onMouseLeave={this.onMouseLeave}
                  onClick={this.onClick}
                  style={{
                    position: "absolute",
                    left: `${left}px`,
                    transform: `translate(${offsetX}px, ${offsetY}px)`,
                    top: `${top}px`,
                  }}
                >
                  <img
                    className="thumbnail-image"
                    alt=""
                    src={`${process.env.REACT_APP_PATH + config.photosMinUrl}${data.photo}`}
                    style={{
                      width: newWidth,
                      height: newHeight,
                      zIndex: 1,
                      top: top < 0 ? "100%" : undefined,
                      bottom: top >= 0 ? "100%" : undefined,
                      left: 0,
                    }}
                  />
                  {word}
                </div>
              );
            })}
          </div>
        </div>
        {this.props.onCloseClick && (
          <div className="cross-button-wrapper">
            <CrossButton onClick={this.onCrossButtonClick} />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Index);
