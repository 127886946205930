import * as React from "react";

const prefix = "img";

const files: any = {
  byId: {
    "pause-bold": {
      width: 29.548,
      height: 45,
      viewBox: [0, 0, 29.548, 45],
      data: '<path xmlns="http://www.w3.org/2000/svg" fill="#fff" d="M10.059,52.6H0V7.6H10.059Zm19.489,0H19.489V7.6H29.548Z" transform="translate(0 -7.6)"/>',
    },
    "arrow-head-bold": {
      width: 29.4,
      height: 56.16,
      viewBox: [0, 0, 29.4, 56.16],
      data: '<path xmlns="http://www.w3.org/2000/svg" fill="#fff" d="M27.24-4.56l24-20.16V-40.56l-24-20.16L21.84-49.2l15.6,16.56L21.84-15.96Z" transform="translate(-21.84 60.72)"/>',
    },
    ellipse: {
      width: 413.39,
      height: 197.977,
      viewBox: [0, 0, 413.39, 197.977],
      data: '<ellipse cx="197.842" cy="64.247" rx="197.842" ry="64.247" transform="translate(0.581 70.95) rotate(-10.244)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>',
    },
    "arrow-head": {
      width: 29.4,
      height: 56.16,
      viewBox: [0, 0, 29.4, 56.16],
      data: '<path xmlns="http://www.w3.org/2000/svg" fill="#fff" d="M3-52.2l-.6.6L21-29.64,2.4-7.56l.6.6L25.8-29.64Z" transform="translate(1.304 57.709)"/>',
    },
    "next-bold": {
      width: 89.4,
      height: 45.171,
      viewBox: [0, 0, 89.4, 45.171],
      data: '<path xmlns="http://www.w3.org/2000/svg" fill="#fff" d="M55.944-32.98,33.359-55.566l-7.56,7.56,5.67,5.67,4.158,3.591-.094.378-7.56-.378H2.646v11.529H27.972l7.56-.378.094.378-4.158,3.591-5.67,5.67,7.56,7.56Z" transform="translate(33.44 55.566)"/>',
    },
    "x-bold": {
      width: 55.2,
      height: 55.2,
      viewBox: [0, 0, 55.2, 55.2],
      data: '<path xmlns="http://www.w3.org/2000/svg" fill="#fff" d="M53.4-9,63.6-19.2,46.2-36.6,63.6-54,53.4-64.2,36-46.8,18.6-64.2,8.4-54,25.8-36.6,8.4-19.2,18.6-9,36-26.4Z" transform="rotate(90 -.3 -8.7)"/>',
    },
    next: {
      width: 89.4,
      height: 45.171,
      viewBox: [0, 0, 89.4, 45.171],
      data: '<path xmlns="http://www.w3.org/2000/svg" fill="#fff" d="M69.12-63.48l-.6.6L85.8-42.48H2.4v3H85.8L68.52-18.96l.6.6L91.8-40.92Z" transform="translate(-2.4 63.486)"/>',
    },
    x: {
      width: 55.2,
      height: 55.2,
      viewBox: [0, 0, 55.2, 55.2],
      data: '<path xmlns="http://www.w3.org/2000/svg" fill="#fff" d="M59.64-66.12l-3.12-3.12L32.28-45,7.92-69.24,4.8-66.12,29.16-41.88,4.8-17.52,7.92-14.4,32.28-38.76,56.52-14.4l3.12-3.12L35.4-41.88Z" transform="translate(-4.62 69.29)"/>',
    },
    pause: {
      width: 29.548,
      height: 45,
      viewBox: [0, 0, 29.548, 45],
      data: '<path xmlns="http://www.w3.org/2000/svg" fill="#fff" d="M3.5,0H5.772V45H3.5ZM24.476,0h2.272V45H24.476Z" transform="translate(-.35)"/>',
    },
  },
  allIds: ["pause-bold", "arrow-head-bold", "ellipse", "arrow-head", "next-bold", "x-bold", "next", "x", "pause"],
};

function SVG(props: any) {
  if (props.isHidden) {
    return <svg display="none">{props.children}</svg>;
  }

  let file = files.byId[props.id];

  if (!file) return null;

  let width = props.width || file.width || null;
  let height = props.height || file.height || null;
  let viewBox = file.viewBox ? file.viewBox.join(" ") : "0 0 " + props.width + " " + props.height;

  return props.isHidden ? (
    <svg display="none">{props.children}</svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      preserveAspectRatio="xMinYMin"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <use xlinkHref={"#" + prefix + "-" + props.id} />
    </svg>
  );
}

function SVGSource(props: any) {
  return (
    <SVG isHidden={true}>
      <defs>
        {files.allIds.reduce((defs: any, fileId: any, fileIndex: any) => {
          const file = files.byId[fileId];
          return defs.concat(
            <g key={fileIndex} id={prefix + "-" + fileId} dangerouslySetInnerHTML={{ __html: file.data }}></g>
          );
        }, [])}
      </defs>
    </SVG>
  );
}

export { SVG, SVGSource };
