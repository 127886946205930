import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { chunk, shuffle } from "lodash";
import memoize from "memoize-one";
import cx from "classnames";
import config from "../../config.json";
import { SVG } from "../../components/svg";
import CrossButton from "../../components/CrossButton";

import "./style.scss";

interface MatchParams {
  tag: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  tags: string[];
  tagPhotos: {
    [key: string]: string[] | undefined;
  };
  photosMap: {
    [key: string]: {
      height: number;
      width: number;
      year: number;
    };
  };
  onBlurBackground: () => void;
}

interface State {
  ready: boolean;
  indexVisible: boolean;
}

const generateGroups = memoize((photos: string[], numberOfGroups: number) => {
  return chunk(shuffle(photos), numberOfGroups);
});

class Photo extends React.Component<any, any> {
  state = {
    loaded: false,
  };

  onLoad = () => {
    this.setState({
      loaded: true,
    });
  };

  render() {
    const { width, height, tag, photo } = this.props;

    return (
      <Link to={`/photos/${photo}`} key={photo}>
        <img
          src={`${process.env.REACT_APP_PATH + config.photosMinUrl}${photo}`}
          alt={tag}
          key={photo}
          width={width}
          height={height}
          className={cx("photo", {
            "photo--visible": this.state.loaded,
          })}
          onLoad={this.onLoad}
        />
      </Link>
    );
  }
}

class Trumpet extends React.Component<any, any> {
  state = {
    playing: false,
  };

  audioElementRef = React.createRef<HTMLAudioElement>();

  onPlayClicked = () => {
    if (this.state.playing) {
      this.audioElementRef.current!.pause();
    } else {
      this.audioElementRef.current!.play();
    }
  };

  onPlaying = () => {
    this.setState({ playing: true });
  };

  onStopped = () => {
    this.setState({ playing: false });
  };

  render() {
    const { width, height } = this.props;

    return (
      <div className="trumpet" style={{ width, height }} onClick={this.onPlayClicked}>
        <audio
          src={`${process.env.REACT_APP_PATH + config.photosUrl}trumpet.mp3`}
          ref={this.audioElementRef}
          onPlaying={this.onPlaying}
          onPause={this.onStopped}
          onEnded={this.onStopped}
        ></audio>
        <div className={cx("play-button", { "play-button--hidden": false })}>
          <SVG id="ellipse" />
          <button className={cx("button")} type="button">
            {this.state.playing ? (
              <>
                <span className="state-hover">
                  <SVG id="pause-bold" />
                </span>
                <span className="state-regular">
                  <SVG id="pause" />
                </span>
              </>
            ) : (
              <>
                <span className="state-hover">
                  <SVG id="arrow-head-bold" />
                </span>
                <span className="state-regular">
                  <SVG id="arrow-head" />
                </span>
              </>
            )}
          </button>
        </div>
      </div>
    );
  }
}

class VideoPlayer extends React.Component<any, any> {
  state = {
    playing: false,
    everPlayed: false,
  };

  videoElementRef = React.createRef<HTMLVideoElement>();

  onPlayClicked = () => {
    if (this.state.playing) {
      this.videoElementRef.current!.pause();
    } else {
      this.videoElementRef.current!.play();
    }
  };

  onPlaying = () => {
    if (!this.state.everPlayed) {
      this.videoElementRef.current!.currentTime = 0;
    }
    this.setState({ playing: true, everPlayed: true });
  };

  onStopped = () => {
    this.setState({ playing: false });
  };

  render() {
    const { width, height } = this.props;

    return (
      <div className="video-player" onClick={this.onPlayClicked}>
        <video
          ref={this.videoElementRef}
          onPlaying={this.onPlaying}
          onPause={this.onStopped}
          preload="auto"
          onEnded={this.onStopped}
          style={{ width, height }}
        >
          <source
            src={`${process.env.REACT_APP_PATH + config.photosUrl}${this.props.video}.mov`}
            type="video/quicktime"
          />
          <source
            src={`${process.env.REACT_APP_PATH + config.photosUrl}${this.props.video}.webm`}
            type={`video/webm;codecs="vp9"`}
          />
        </video>
        <div className={cx("play-button", { "play-button--hidden": this.state.playing })}>
          <SVG id="ellipse" />
          <button className={cx("button")} type="button">
            <span className="state-hover">
              <SVG id="arrow-head-bold" />
            </span>
            <span className="state-regular">
              <SVG id="arrow-head" />
            </span>
          </button>
        </div>
      </div>
    );
  }
}

class Photos extends React.PureComponent<Props, State> {
  private containerWidth: number = 0;
  private containerHeight: number = 0;
  private containerRef = React.createRef<HTMLDivElement>();

  state = {
    ready: false,
    indexVisible: false,
  };

  componentDidMount() {
    if (this.containerRef.current) {
      const boundingRect = this.containerRef.current.getBoundingClientRect();

      this.containerWidth = boundingRect.width;
      this.containerHeight = boundingRect.height;

      this.setState({
        ready: true,
      });
    }
  }

  componentWillUnmount() {
    generateGroups.clear();
  }

  private getGridSize = (photosNumber: number): [number, number] => {
    const ratio = this.containerWidth / this.containerHeight;

    const a = Math.ceil(Math.sqrt(photosNumber / ratio));
    const b = Math.ceil(photosNumber / a);

    return [a, b];
  };

  private calculateWidth = (newHeight: number, width: number, height: number) => {
    const ratio = width / height;
    const newWidth = ratio * newHeight;

    return newWidth;
  };

  renderGrid(photos: string[]) {
    const [vertical, horizontal] = this.getGridSize(photos.length);
    const rowHeight = Math.floor(this.containerHeight / vertical);

    const groups = generateGroups(photos, horizontal);

    let maxWidth = 0;

    groups.forEach((photos) => {
      let sumWidth = 0;

      photos.forEach((photo) => {
        const { width, height } = this.props.photosMap[photo];

        sumWidth += this.calculateWidth(rowHeight, width, height);
      });

      maxWidth = Math.max(maxWidth, sumWidth);
    });

    const scale = maxWidth > this.containerWidth ? this.containerWidth / maxWidth : 1;

    return (
      <div style={{ transform: `scale(${scale})` }}>
        {groups.map((group) => {
          const id = group.join("-");

          return (
            <div className="photos-row" key={id}>
              {group.map((photo) => {
                const { width, height } = this.props.photosMap[photo];

                if (photo === "trumpet") {
                  return (
                    <Trumpet height={rowHeight} width={this.calculateWidth(rowHeight, width, height)} key={photo} />
                  );
                } else if (photo.startsWith("conductor") || photo.startsWith("trophy")) {
                  return (
                    <VideoPlayer
                      height={rowHeight}
                      width={this.calculateWidth(rowHeight, width, height)}
                      video={photo}
                      key={photo}
                    />
                  );
                } else {
                  return (
                    <Photo
                      width={this.calculateWidth(rowHeight, width, height)}
                      height={rowHeight}
                      tag={this.props.match.params.tag}
                      photo={photo}
                      key={photo}
                    />
                  );
                }
              })}
            </div>
          );
        })}
      </div>
    );
  }

  onCrossButtonClick = () => {};

  render() {
    const {
      history: { goBack },
      match: {
        params: { tag },
      },
      tagPhotos,
    } = this.props;

    const { ready } = this.state;

    const photos = tagPhotos[tag] || [];

    return (
      <div className="page-photos">
        <div className="photos" ref={this.containerRef}>
          {ready ? this.renderGrid(photos) : null}
        </div>
        <div className="cross-button-wrapper">{<CrossButton onClick={goBack} />}</div>
        <div className="link-button-wrapper link-button-wrapper__index">
          <button className="link-button" onClick={this.props.onBlurBackground}>
            index
          </button>
        </div>
        <div className="link-button-wrapper link-button-wrapper__start">
          <Link to="/" className="link-button">
            start
          </Link>
        </div>
        <div className="tag-name">#{tag}</div>
      </div>
    );
  }
}

export default Photos;
