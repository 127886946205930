/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/img-redundant-alt */

import cx from "classnames";
import React, { Component } from "react";
import config from "./config.json";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages/home/Home";
import Photos from "./pages/photos/Photos";
import Photo from "./pages/photo/Photo";
import Loader from "./pages/loader/Loader";
import Time from "./pages/time/Time";
import PhotoBackground from "./components/PhotoBackground";
import { SVGSource } from "./components/svg";
import IndexController from "./components/IndexController";
import AboutController from "./components/AboutController";
import Intro from "./components/Intro";
import SmallScreenBlocker from "./components/SmallScreenBlocker";

import "./App.scss";

type Face = {
  x: number;
  y: number;
  width: number;
};

type Photo2 = {
  tags: string[];
  year: number;
  width: number;
  height: number;
  face?: Face;
  offset?: {
    top: number;
    left: number;
    scale: number;
  };
};

type State = {
  pending: boolean;
  backgroundPhoto: string | undefined;
  indexVisible: boolean;
  aboutVisible: boolean;
  introVisible: boolean;
  renderLoader: boolean;
  db: any;
  showBlocker: boolean;
};

type PhotosPayload = {
  photos: Array<
    {
      id: string;
      face?: Face;
    } & Photo2
  >;
};

const frameWidth = 140;
// const frameHeight = (frameWidth * 1) / 0.7252196168; // 193,0449711465

const calculateOffset = (width: number, face?: Face) => {
  if (face === undefined) {
    return undefined;
  }

  const { width: faceWidth, x, y } = face;

  const scale = frameWidth / faceWidth;
  const top = Math.floor(-y * scale);
  const left = Math.floor(-x * scale);

  return {
    top,
    left,
    scale,
  };
};

class App extends Component<any, State> {
  tagsList: string[] = [];
  tagPhotos: {
    [key: string]: string[];
  } = {};
  photoTags: {
    [key: string]: string[];
  } = {};
  photosMap: {
    [key: string]: {
      face: Face | undefined;
      height: number;
      width: number;
      year: number;
    };
  } = {};
  photosWithFace: string[] = [];

  constructor(props) {
    super(props);

    const width = window.innerWidth;
    const state = {
      pending: true,
      backgroundPhoto: "ZK_A_0011.webp",
      indexVisible: false,
      aboutVisible: false,
      introVisible: false,
      renderLoader: false,
      showBlocker: false,
    };

    if (width >= 1366) {
      this.loadRequiredResources();
    } else {
      state.showBlocker = true;
    }

    this.state = state;
  }

  componentDidMount() {
    window.addEventListener("resize", () => {
      const width = window.innerWidth;

      if (width >= 1366 && this.state.showBlocker) {
        this.setState({
          showBlocker: false,
        });

        this.loadRequiredResources();
      }
    });
  }

  onSetBackgroundPhoto = (photo: string | undefined) => {
    this.setState({ backgroundPhoto: photo });
  };

  onShowIndex = () => {
    this.setState({
      indexVisible: true,
    });
  };

  onHideIndex = () => {
    this.setState({
      indexVisible: false,
    });
  };

  onShowAbout = () => {
    this.setState({
      aboutVisible: true,
    });
  };

  onHideAbout = () => {
    this.setState({
      aboutVisible: false,
    });
  };

  renderPhotoBackground() {
    const { backgroundPhoto } = this.state;

    if (backgroundPhoto) {
      return <PhotoBackground photo={backgroundPhoto} />;
    }
  }

  onImagesLoaded = (photos: PhotosPayload["photos"], mainPhotos: any) => {
    const tagsPhotosMap = new Map();
    const photosMap = new Map();
    const photosOrdered: string[] = [];

    photos.forEach(({ id, tags, year, width, height, face }) => {
      const newFace = face;
      photosMap.set(id, {
        tags,
        year,
        width,
        height,
        face: newFace,
        offset: calculateOffset(width, newFace),
      });

      if (face !== undefined) {
        photosOrdered.push(id);

        this.photosWithFace.push(id);
      }

      this.photosMap[id] = {
        year,
        width,
        height,
        face,
      };

      tags.forEach((tag) => {
        if (!tagsPhotosMap.has(tag)) {
          tagsPhotosMap.set(tag, []);
        }

        tagsPhotosMap.get(tag).push(id);

        if (!this.tagPhotos[tag]) {
          this.tagPhotos[tag] = [];
        }

        this.tagPhotos[tag].push(id);
      });

      this.photoTags[id] = tags;
    });

    this.photosMap.trumpet = {
      year: 0,
      width: 960,
      height: 720,
      face: undefined,
    };
    this.photosMap.conductor1 = {
      year: 0,
      width: 450,
      height: 360,
      face: undefined,
    };
    this.photosMap.conductor2 = {
      year: 0,
      width: 450,
      height: 360,
      face: undefined,
    };
    this.photosMap.conductor3 = {
      year: 0,
      width: 450,
      height: 360,
      face: undefined,
    };
    this.photosMap.conductor4 = {
      year: 0,
      width: 450,
      height: 360,
      face: undefined,
    };
    this.photosMap.trophy1 = {
      year: 0,
      width: 450,
      height: 360,
      face: undefined,
    };
    this.tagPhotos.trumpet.unshift("trumpet");
    this.tagPhotos.conductor.unshift("conductor1");
    this.tagPhotos.conductor.unshift("conductor2");
    this.tagPhotos.conductor.unshift("conductor3");
    this.tagPhotos.conductor.unshift("conductor4");
    this.tagPhotos.trophy.unshift("trophy1");

    this.tagsMainPhotoMap = mainPhotos.reduce((result, tag) => {
      result[tag.tag] = {
        photo: tag.photo,
        width: this.photosMap[tag.photo].width,
        height: this.photosMap[tag.photo].height,
      };

      return result;
    }, {});

    this.tagsList = [...tagsPhotosMap.keys()];

    this.setState({
      pending: false,
    });
  };

  onLoaderHidden = (e) => {
    if (e.target.classList.contains("loader-wrapper")) {
      this.setState({
        renderLoader: false,
      });
    }
  };

  onShowIntro = () => {
    this.setState({
      introVisible: true,
    });
  };

  onIntroFinished = () => {
    this.setState({
      introVisible: false,
    });
  };

  renderContent() {
    return (
      <Router>
        {this.renderPhotoBackground()}
        <div
          className={cx("blur-wrapper", {
            "blur-wrapper--visible": this.state.indexVisible || this.state.aboutVisible,
          })}
        >
          <Switch>
            <Route
              path="/time"
              render={(routeProps) => {
                return <Time {...routeProps} photosIds={this.photosWithFace} photosMap={this.photosMap} />;
              }}
            />
            <Route
              path="/photos/:photo"
              render={(routeProps) => {
                const photo = routeProps.match.params.photo;

                return (
                  <Photo
                    onSetBackgroundPhoto={this.onSetBackgroundPhoto}
                    photo={photo}
                    hasFace={this.photosMap[photo] !== undefined}
                    photosMap={this.photosMap}
                    photoTags={this.photoTags}
                  />
                );
              }}
            />
            <Route
              path="/:tag"
              render={(routeProps) => (
                <Photos
                  {...routeProps}
                  photosMap={this.photosMap}
                  tagPhotos={this.tagPhotos}
                  tags={this.tagsList}
                  onBlurBackground={this.onShowIndex}
                />
              )}
            />
            <Route path="/">
              <Home
                tags={this.tagsList}
                onShowIntro={this.onShowIntro}
                tagsMainPhotoMap={this.tagsMainPhotoMap}
                onShowIndex={this.onShowIndex}
                onShowAbout={this.onShowAbout}
              />
            </Route>
          </Switch>
        </div>
        <IndexController
          tags={this.tagsList}
          tagsMainPhotoMap={this.tagsMainPhotoMap}
          onHide={this.onHideIndex}
          visible={this.state.indexVisible}
        />
        <AboutController onHide={this.onHideAbout} visible={this.state.aboutVisible} />
      </Router>
    );
  }

  loadRequiredResources() {
    const fontsToRender = ["nocturne_seriflight_italic", "nocturne_seriflight", "IBM Plex Mono"];

    const bgImageReady = new Promise((resolve) => {
      const img = new Image();
      img.src = `${process.env.REACT_APP_PATH + config.photosUrl}${config.mainImage}`;

      img.onload = resolve;
    });

    Promise.all([
      this.fetchData(),
      ...fontsToRender.map((font) => document.fonts.load(`12px ${font}`)),
      bgImageReady,
    ]).then((data) => {
      const db = data[0];

      this.setState({
        db,
        renderLoader: true,
      });
    });
  }

  fetchData = () => {
    return fetch(`${process.env.REACT_APP_PATH + config.dbUrl}?` + +new Date(), {
      method: "GET",
      credentials: "omit",
      mode: "cors",
    }).then((response) => response.json());
  };

  render() {
    const { pending, introVisible, renderLoader, db, showBlocker } = this.state;

    return (
      <div className="app">
        <SVGSource />
        {showBlocker && <SmallScreenBlocker />}
        <div className={cx("initial-background")}></div>
        {pending === false && this.renderContent()}
        {renderLoader && (
          <div
            className={cx("loader-wrapper", { "loader-wrapper--hidden": pending === false })}
            onTransitionEnd={this.onLoaderHidden}
          >
            <Loader imagesLoaded={this.onImagesLoaded} db={db} />
          </div>
        )}
        {introVisible && <Intro onFinished={this.onIntroFinished} />}
      </div>
    );
  }
}

export default App;
