import * as React from "react";
import cx from "classnames";
import About from "../About";

import "./style.scss";

type Props = {
  onShow?: () => void;
  onHide?: () => void;
  visible: boolean;
};

class AboutController extends React.Component<Props> {
  showAbout = () => {
    this.props.onShow && this.props.onShow();
  };

  hideAbout = () => {
    this.props.onHide && this.props.onHide();
  };

  render() {
    const { visible } = this.props;

    return (
      <div
        className={cx("about-wrapper", {
          "about-wrapper--visible": visible,
        })}
      >
        <About onCloseClick={this.hideAbout} />
      </div>
    );
  }
}

export default AboutController;
