import config from "../config.json";

class ImagePrefetcher {
  document = document.body;

  prefetchInitialImages(list: any, onImageFetched) {
    list.forEach((photo) => {
      const img = new Image();
      document.body.append(img);
      img.src = process.env.REACT_APP_PATH + config.photosMinUrl + photo;
      img.classList.add("prefetched-image");
      img.onload = onImageFetched;
    });
  }
}

export default new ImagePrefetcher();
