import * as React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import ProgressiveImage from "react-progressive-image";

import config from "../../config.json";

import "./style.scss";

interface Props {
  hasFace: boolean;
  photo: string;
  photoTags: {
    [key: string]: string[] | undefined;
  };
  photosMap: {
    [key: string]: {
      height: number;
      width: number;
      year: number;
    };
  };
  onSetBackgroundPhoto: (photoId: string) => void;
}

interface State {
  ready: boolean;
  pending: boolean;
}

class Photo extends React.Component<Props, State> {
  private containerHeight: number = 0;
  private containerRef = React.createRef<HTMLDivElement>();

  state = {
    ready: false,
    pending: this.props.photoTags[this.props.photo] !== undefined,
  };

  componentDidMount() {
    if (this.containerRef.current) {
      const boundingRect = this.containerRef.current.getBoundingClientRect();

      this.containerHeight = boundingRect.height;

      this.setState({
        ready: true,
      });
    }

    this.props.onSetBackgroundPhoto(this.props.photo);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.photo !== this.props.photo) {
      const photoExists = this.props.photoTags[this.props.photo] !== undefined;

      this.setState({
        pending: photoExists,
      });
    }
  }

  onImageLoad = () => {
    this.setState({
      pending: false,
    });
  };

  private calculateWidth = (newHeight: number, width: number, height: number) => {
    const ratio = width / height;
    const newWidth = ratio * newHeight;

    return newWidth;
  };

  getPhotoStyle = () => {
    const { photo, photosMap } = this.props;
    const { width, height } = photosMap[photo];

    return {
      height: this.containerHeight,
      width: this.calculateWidth(this.containerHeight, width, height),
    };
  };

  renderPage(children: React.ReactNode) {
    return (
      <div className="page-photo">
        {children}
        <div className="link-button-wrapper link-button-wrapper__start">
          <Link to="/" className="link-button">
            start
          </Link>
        </div>
      </div>
    );
  }

  renderTag(tag: string | undefined) {
    return (
      tag && (
        // eslint-disable-next-line
        <Link
          className={cx("tag", {
            "tag--time": tag === "time",
          })}
          key={tag}
          to={`/${tag}`}
        >
          {tag}
        </Link>
      )
    );
  }

  createTagsList() {
    const { photo, photoTags, hasFace } = this.props;

    const tags = [...photoTags[photo]] || [];

    if (hasFace) {
      tags.push("time");
    }

    const outputTags: string[] = [];
    outputTags.length = 8;

    if (tags.length === 1) {
      outputTags[1] = tags.shift();
    } else if (tags.length === 2) {
      outputTags[1] = tags.shift();
      outputTags[6] = tags.shift();
    } else if (tags.length === 3) {
      outputTags[1] = tags.shift();
      outputTags[7] = tags.shift();
      outputTags[5] = tags.shift();
    } else if (tags.length === 4) {
      outputTags[1] = tags.shift();
      outputTags[6] = tags.shift();
      outputTags[7] = tags.shift();
      outputTags[5] = tags.shift();
    } else if (tags.length === 5) {
      outputTags[1] = tags.shift();
      outputTags[2] = tags.shift();
      outputTags[6] = tags.shift();
      outputTags[7] = tags.shift();
      outputTags[5] = tags.shift();
    } else if (tags.length === 6) {
      outputTags[0] = tags.shift();
      outputTags[1] = tags.shift();
      outputTags[2] = tags.shift();
      outputTags[6] = tags.shift();
      outputTags[7] = tags.shift();
      outputTags[5] = tags.shift();
    } else if (tags.length === 7) {
      outputTags[1] = tags.shift();
      outputTags[2] = tags.shift();
      outputTags[3] = tags.shift();
      outputTags[4] = tags.shift();
      outputTags[6] = tags.shift();
      outputTags[7] = tags.shift();
      outputTags[5] = tags.shift();
    } else {
      outputTags[0] = tags.shift();
      outputTags[1] = tags.shift();
      outputTags[2] = tags.shift();
      outputTags[3] = tags.shift();
      outputTags[4] = tags.shift();
      outputTags[6] = tags.shift();
      outputTags[7] = tags.shift();
      outputTags[5] = tags.shift();
    }

    return outputTags;
  }

  render() {
    const { photo, photoTags } = this.props;
    const { ready } = this.state;

    if (photoTags[photo] === undefined) {
      return this.renderPage(<div className="not-found">Photo not found</div>);
    }

    const tags = this.createTagsList();

    return this.renderPage(
      <>
        <div className="photo" ref={this.containerRef}>
          {ready && (
            <ProgressiveImage
              src={`${process.env.REACT_APP_PATH + config.photosUrl}${photo}`}
              placeholder={`${process.env.REACT_APP_PATH + config.photosMinUrl}${photo}`}
            >
              {(src: string) => <img src={src} alt={photo} style={this.getPhotoStyle()} />}
            </ProgressiveImage>
          )}
        </div>
        <div className="tags">
          <div className="tag-column">
            <div className="tag-slot">{this.renderTag(tags[0])}</div>
            <div className="tag-slot">{this.renderTag(tags[1])}</div>
            <div className="tag-slot">{this.renderTag(tags[2])}</div>
          </div>
          <div className="tag-column">
            <div className="tag-slot">{this.renderTag(tags[3])}</div>
            <div className="tag-slot">{this.renderTag(tags[4])}</div>
          </div>
          <div className="tag-column">
            <div className="tag-slot">{this.renderTag(tags[5])}</div>
            <div className="tag-slot">{this.renderTag(tags[6])}</div>
            <div className="tag-slot">{this.renderTag(tags[7])}</div>
          </div>
        </div>
      </>
    );
  }
}

export default Photo;
